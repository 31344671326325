<template>
  <base-material-card
    :icon="icon"
    color="secondary"
    :title="title"
  >
    <v-form
      ref="companyClassForm"
      lazy-validation
      @submit.prevent="updateCompanyClass"
    >
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="companyClass.name"
            label="Name *"
            :rules="[required]"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="companyClass.tin"
            label="Federal Employer Identification Number (FEIN)"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="companyClass.dba"
            label="Doing Business As Name"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-autocomplete
            v-model="companyClass.company_type_id"
            label="Type"
            item-text="type"
            item-value="id"
            :items="mixinItems.companyTypes"
            :loading="loadingMixins.companyTypes"
          />
        </v-col>
      </v-row>

      <v-btn
        fab
        absolute
        bottom
        right
        small
        type="submit"
        :loading="updating"
      >
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-form>
  </base-material-card>
</template>

<script>
  import { mapState } from 'vuex'
  import { Api } from '@/apis'
  import { fetchInitials } from '@/mixins/fetchInitials'
  import { MIXINS } from '@/constants'

  export default {
    mixins: [
      fetchInitials([
        MIXINS.companyTypes,
      ]),
    ],

    props: {
      icon: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      required: value => !!value || 'This field is required.',
      updating: false,
    }),

    computed: {
      ...mapState({
        companyClass: state => state.items.companyClass,
      }),
    },

    methods: {
      async updateCompanyClass () {
        if (this.$refs.companyClassForm.validate()) {
          this.updating = true
          await new Api().put({
            url: 'agencies/agency/' + this.$route.params.id,
            request: this.companyClass,
          })
          this.updating = false
        }
      },
    },
  }
</script>
